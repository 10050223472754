export function useToggleMenu() {
  const toggleMenu = (className: string): void => {
    document.querySelector('body')?.classList.toggle(className)
    const accountLayout = document.querySelector('.account-layout');
    if (accountLayout) {
      accountLayout.scrollTop = 0;
    }
  }

  const removeMenu = () => {
    const body = document.querySelector('body')
    const classesToRemove = ['show-menu-account', 'show-menu-deposit', 'show-menu-withdraw']
    if (body) {
      classesToRemove.forEach((className) => {
        body.classList.remove(className)
      })
    }
  }
  return {
    toggleMenuAccount: () => toggleMenu('show-menu-account'),
    toggleMenuDeposit: () => toggleMenu('show-menu-deposit'),
    toggleMenuWithdraw: () => toggleMenu('show-menu-withdraw'),
    removeMenu
  }
}
